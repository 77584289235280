import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';

import { useHttpCache } from '@silae/composables';
import { WorkplaceDTO, fetchWorkplaces$ } from '~/api';

import { Clearable } from './store.domain';

export type WorkplaceStore = Clearable & {
	fetchWorkplaces$: (companyId: number, invalidate?: boolean) => Observable<Array<WorkplaceDTO>>;
};

export const useWorkplaceStore = defineStore<'workplace-store', WorkplaceStore>('workplace-store', () => {
	const { cache$: cacheWorkplaces$, clearCache: clearWorkplacesCache } = useHttpCache<number, Array<WorkplaceDTO>>();

	const clear = () => {
		clearWorkplacesCache();
	};

	const _fetchWorkplaces$ = (companyId: number, invalidate?: boolean): Observable<Array<WorkplaceDTO>> => {
		if (invalidate) clearWorkplacesCache();
		return cacheWorkplaces$(companyId, fetchWorkplaces$(companyId));
	};

	return {
		clear,
		fetchWorkplaces$: _fetchWorkplaces$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useWorkplaceStore, import.meta.hot));
