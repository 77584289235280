export enum Role {
	EMPLOYEE = 'employee',
	MANAGER = 'manager',
	ADMIN = 'admin'
}

export type RoleDetails = {
	label: string;
	icon: string;
	color: string;
	backgroundColor: string;
};

export const ROLES_DETAILS: Record<Role, RoleDetails> = {
	[Role.EMPLOYEE]: {
		label: 'common.roles.employee',
		icon: 'role-employee',
		color: 'text-cumulus-text',
		backgroundColor: 'cumulus10'
	},
	[Role.MANAGER]: {
		label: 'common.roles.manager',
		icon: 'role-manager',
		color: 'text-terracotta-text',
		backgroundColor: 'terracotta10'
	},
	[Role.ADMIN]: {
		label: 'common.roles.admin',
		icon: 'role-admin',
		color: 'text-pink-text',
		backgroundColor: 'purple10'
	}
};
