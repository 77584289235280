import { acceptHMRUpdate, defineStore } from 'pinia';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { AxiosApiError, ISODateString, LeaveDaysCountersDTO, getLeaveDaysCounters$ } from '~/api';
import { useToasts } from '~/composables/messages.composables';
import { useTracking } from '~/composables/tracking.composables';

import { Clearable } from '../store.domain';

export type LeaveDaysCountersStore = Clearable & {
	counters: ComputedRef<Array<LeaveDaysCountersDTO>>;
	fetchCounters$: (companyId: number, at?: ISODateString) => Observable<Array<LeaveDaysCountersDTO>>;
	invalidateCounters: () => void;
};

export const useLeaveDaysCountersStore = defineStore<'leave-days-counters', LeaveDaysCountersStore>('leave-days-counters', () => {
	const _counters: Ref<Array<LeaveDaysCountersDTO>> = ref([]);
	const { axiosError } = useToasts();
	const { track } = useTracking();

	const clear = () => {
		clearCache();
		_counters.value = [];
	};

	const { cache$, clearCache } = useHttpCache<string, Array<LeaveDaysCountersDTO>>(60000);
	const _fetchCounters$ = (companyId: number, at?: ISODateString) => {
		const key = getCacheKey(companyId, at);

		return cache$(key, getLeaveDaysCounters$(companyId, at)).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			tap((c: Array<LeaveDaysCountersDTO>) => {
				if (at != null) track('Monthly Balances Simulated', { companyId }, { simulation_date: at });
				_counters.value = c ?? [];
			})
		);
	};

	return {
		clear,
		counters: computed(() => _counters.value),
		fetchCounters$: _fetchCounters$,
		invalidateCounters: clearCache
	};
});

function getCacheKey(companyId: number, at?: ISODateString): string {
	return `${companyId}${at != null ? `-${at}` : ''}`;
}

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useLeaveDaysCountersStore, import.meta.hot));
