import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

const { konami } = useKonamiCode();

export const AdminEmployeeListRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_EMPLOYEE_LIST,
	path: '',
	components: {
		default: () => import('./employee-list.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(
				() =>
					!isAdmin.value ||
					Devices().isMobile ||
					!(useFeatures().hasEmployeeDetails.value || useFeatures().hasEmployeeDetailsRestricted.value) ||
					!konami.value
			);
		},
		isSubEntry: true,
		label: 'admin.navigation.employees.list',
		requiresAuth: true,
		order: 3
	}
};
