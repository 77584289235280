import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { RouteKeys } from '~/router/routes.domain';


export const PublicDownloadRoute: RouteRecordRaw = {
	name: RouteKeys.PUBLIC_DOWNLOAD,
	path: '/files/:token',
	components: {
		default: () => import('./public-download.vue')
	},
	props: {
		default: route => ({ token: route.params.token })
	},
	meta: {
		public: true,
		isForbidden: () => {
			const { konami } = useKonamiCode();
			return computed(() => !konami.value);
		}
	}
};

export const PublicDownloadExpiredRoute: RouteRecordRaw = {
	name: RouteKeys.PUBLIC_DOWNLOAD_EXPIRED,
	path: '/files/:token/expired',
	components: {
		default: () => import('./public-download-expired.vue')
	},
	meta: {
		public: true,
		requiresAuth: false,
		isForbidden: () => {
			const { konami } = useKonamiCode();
			return computed(() => !konami.value);
		}
	}
};
