import { defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import {
	AxiosApiError,
	PotentialMemberDTO,
	TeamDTO,
	TeamMemberDTO,
	TeamType,
	getAllTeams$,
	getAllUnassignedMembers$,
	getPotentialManagers$,
	getPotentialMembersForExistingTeam$,
	getPotentialMembersForNewTeam$
} from '~/api';
import { useToasts } from '~/composables/messages.composables';
import { PotentialMember, Team, UnassignedMember } from '~/domain';
import { useCompaniesStore } from '~/stores/companies.store';
import { Clearable } from '~/stores/store.domain';
import { asPotentialManager, asPotentialMember, asTeam, asUnassignedMember } from '~/utils/team.utils';

export type TeamsStore = Clearable & {
	fetchPotentialManagers$: (
		companyId: number,
		type: TeamType,
		teamId?: string,
		invalidateCache?: boolean
	) => Observable<Array<PotentialMember>>;
	fetchPotentialMembersForExistingTeam$: (
		companyId: number,
		teamId: string,
		managerId: string,
		invalidateCache?: boolean
	) => Observable<Array<PotentialMember>>;
	fetchPotentialMembersForNewTeam$: (
		companyId: number,
		type: TeamType,
		managerId: string,
		invalidateCache?: boolean
	) => Observable<Array<PotentialMember>>;
	fetchTeams$: (companyId: number, invalidateCache?: boolean) => Observable<Array<Team>>;
	fetchUnassignedMembers$: (companyId: number, type: TeamType, invalidateCache?: boolean) => Observable<Array<UnassignedMember>>;
	potentialManagers: ComputedRef<Array<PotentialMember>>;
	potentialMembers: ComputedRef<Array<PotentialMember>>;
	teams: ComputedRef<Array<Team>>;
	unassignedMembers: ComputedRef<Array<UnassignedMember>>;
};

export const useTeamsStore = defineStore<'teams', TeamsStore>('teams', () => {
	const { axiosError } = useToasts();
	const { administeredCompaniesById } = storeToRefs(useCompaniesStore());

	const _teams: Ref<Array<Team>> = ref([]);
	const teams: ComputedRef<Array<Team>> = computed(() => _teams.value);
	const _potentialManagers: Ref<Array<PotentialMember>> = ref([]);
	const potentialManagers: ComputedRef<Array<PotentialMember>> = computed(() =>
		_potentialManagers.value?.toSorted((a, b) => a.fullName?.localeCompare(b?.fullName))
	);
	const _potentialMembers: Ref<Array<PotentialMember>> = ref([]);
	const potentialMembers: ComputedRef<Array<PotentialMember>> = computed(() =>
		_potentialMembers.value?.toSorted((a, b) => a.fullName?.localeCompare(b?.fullName))
	);
	const _unassignedMembers: Ref<Array<UnassignedMember>> = ref([]);
	const unassignedMembers: ComputedRef<Array<UnassignedMember>> = computed(() =>
		_unassignedMembers.value?.toSorted((a, b) => a.fullName?.localeCompare(b?.fullName))
	);

	const isAuthorized = (companyId: number): boolean => {
		const hasThisCompany = administeredCompaniesById.value.has(companyId);
		return hasThisCompany ? administeredCompaniesById.value.get(companyId).isAdmin : false;
	};

	const { cache$: teamsCache$, clearCache: clearTeamsCache } = useHttpCache<number, Array<TeamDTO>>(1000);
	const { cache$: potentialManagersCache$, clearCache: clearPotentialManagersCache } = useHttpCache<string, Array<PotentialMemberDTO>>(
		1000
	);
	const { cache$: potentialMembersExistingTeamCache$, clearCache: clearPotentialMembersExistingTeamCache } = useHttpCache<
		string,
		Array<PotentialMemberDTO>
	>(1000);
	const { cache$: potentialMembersNewTeamCache$, clearCache: clearPotentialMembersNewTeamCache } = useHttpCache<
		string,
		Array<PotentialMemberDTO>
	>(1000);
	const { cache$: unassignedMembersCache$, clearCache: clearUnassignedMembersCache } = useHttpCache<number, Array<TeamMemberDTO>>(1000);

	const _fetchTeams$ = (companyId: number, invalidateCache?: boolean) => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearTeamsCache();
		}

		return teamsCache$(companyId, getAllTeams$(companyId)).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			map(teams => teams.map(asTeam)),
			tap(value => (_teams.value = value))
		);
	};

	const _fetchPotentialManagers$ = (companyId: number, type: TeamType, teamId?: string, invalidateCache?: boolean) => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearPotentialManagersCache();
		}

		let cacheKey: string;
		if (teamId) {
			cacheKey = `${companyId}-${teamId}`;
		} else {
			cacheKey = `${companyId}`;
		}

		return potentialManagersCache$(cacheKey, getPotentialManagers$(companyId, type, teamId)).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			map(potentialManagers => potentialManagers.map(asPotentialManager)),
			tap(potentialManagers => (_potentialManagers.value = potentialManagers))
		);
	};

	const _fetchPotentialMembersForExistingTeam$ = (companyId: number, teamId: string, managerId: string, invalidateCache?: boolean) => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearPotentialMembersExistingTeamCache();
		}

		return potentialMembersExistingTeamCache$(
			`${companyId}-${teamId}-${managerId}`,
			getPotentialMembersForExistingTeam$(companyId, teamId, managerId)
		).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			map(potentialMembers => potentialMembers.map(potentialMember => asPotentialMember(potentialMember))),
			tap(potentialMembers => (_potentialMembers.value = potentialMembers))
		);
	};

	const _fetchPotentialMembersForNewTeam$ = (companyId: number, type: TeamType, managerId: string, invalidateCache?: boolean) => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearPotentialMembersNewTeamCache();
		}

		return potentialMembersNewTeamCache$(
			`${companyId}-${type}-${managerId}`,
			getPotentialMembersForNewTeam$(companyId, type, managerId)
		).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			map(potentialMembers => potentialMembers.map(potentialMember => asPotentialMember(potentialMember))),
			tap(potentialMembers => (_potentialMembers.value = potentialMembers))
		);
	};

	const _fetchUnassignedMembers$ = (companyId: number, type: TeamType, invalidateCache?: boolean) => {
		if (!isAuthorized(companyId)) {
			return EMPTY;
		}

		if (invalidateCache) {
			clearUnassignedMembersCache();
		}

		return unassignedMembersCache$(companyId, getAllUnassignedMembers$(companyId, type)).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			map(unassignedMembers => unassignedMembers.map(asUnassignedMember)),
			tap(unassignedMembers => (_unassignedMembers.value = unassignedMembers))
		);
	};

	const clear = () => {
		_teams.value = [];
		clearTeamsCache();
		clearPotentialManagersCache();
		clearPotentialMembersExistingTeamCache();
		clearPotentialMembersNewTeamCache();
		clearUnassignedMembersCache();
	};

	return {
		clear,
		fetchPotentialManagers$: _fetchPotentialManagers$,
		fetchPotentialMembersForExistingTeam$: _fetchPotentialMembersForExistingTeam$,
		fetchPotentialMembersForNewTeam$: _fetchPotentialMembersForNewTeam$,
		fetchTeams$: _fetchTeams$,
		fetchUnassignedMembers$: _fetchUnassignedMembers$,
		potentialManagers,
		potentialMembers,
		teams,
		unassignedMembers
	};
});
