import { Observable } from 'rxjs';

import { useBackendHttpService } from '~/api/http';

import { ADMIN_BASE_URL } from '../defs';
import { WorkplaceDTO } from './workplaces.dto';

const API = 'workplaces';

export function fetchWorkplaces$(companyId: number): Observable<WorkplaceDTO[]> {
	const { http } = useBackendHttpService();
	return http.get(`${ADMIN_BASE_URL}/${companyId}/${API}`);
}
