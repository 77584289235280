import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { EMPTY, Observable } from 'rxjs';

import { useHttpCache } from '@silae/composables';
import { EmployeeOffboardingDTO, fetchAdministeredOffboardingEmployees$ } from '~/api';

import { useCompaniesStore } from '../companies.store';
import { Clearable } from '../store.domain';

export type AdministeredOffboardingEmployeesStore = Clearable & {
	fetchAdministeredOffboardingEmployees$: (companyId: number, invalidateCache?: boolean) => Observable<Array<EmployeeOffboardingDTO>>;
};

export const useAdministeredOffboardingEmployeesStore = defineStore<
	'administered-offboarding-employee-store',
	AdministeredOffboardingEmployeesStore
>('administered-offboarding-employee-store', () => {
	const { cache$, clearCache } = useHttpCache<number, Array<EmployeeOffboardingDTO>>();
	const { companiesById } = storeToRefs(useCompaniesStore());

	const clear = () => clearCache();

	const isAuthorized = (companyId: number): boolean => {
		const hasThisCompany = companiesById.value.has(companyId);
		return hasThisCompany ? companiesById.value.get(companyId).isAdmin : false;
	};

	const fetchEmployees$ = (companyId: number, invalidateCache?: boolean): Observable<Array<EmployeeOffboardingDTO>> => {
		if (!isAuthorized(companyId)) return EMPTY;

		if (invalidateCache) clearCache();

		return cache$(companyId, fetchAdministeredOffboardingEmployees$(companyId));
	};

	return {
		clear,
		fetchAdministeredOffboardingEmployees$: fetchEmployees$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAdministeredOffboardingEmployeesStore, import.meta.hot));
